.lia-version-number {
  display: inline-block;
  line-height: 1;
  border-radius: var(--lia-bs-border-radius-sm);
  border: 1px solid var(--lia-bs-border-color);
  padding: 3px 5px;
  letter-spacing: 0.5px;
  font-size: var(--lia-font-size-xxs);
  background-color: var(--lia-bs-white);
  font-weight: var(--lia-font-weight-md);
  color: var(--lia-bs-body-color);
  margin-right: 10px;
  border-top: 1px solid var(--lia-bs-border-color);
  box-shadow: var(--lia-bs-box-shadow-sm);
}
