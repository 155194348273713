@import '@aurora/shared-client/styles/_variables.pcss';

.lia-version-accordion-toggle {
  display: flex;
  justify-content: space-between;
  background-color: var(--lia-bs-gray-200);
  padding: 15px 25px;
  align-items: center;
  height: 75px;
  cursor: pointer;
  width: 100%;
  border: none;

  @media (--lia-breakpoint-down-md) {
    padding-left: 15px;
    padding-right: 10px;
    max-width: 100%;
  }

  &:hover {
    .lia-accordion-icon-wrap {
      background-color: var(--lia-bs-gray-300);
    }
  }

  &.lia-accordion-is-open {
    .lia-accordion-icon {
      transform: rotate(90deg);
    }
    &.lia-accordion-has-subtext {
      padding-bottom: 4px;

      .lia-accordion-subtext {
        display: block;
      }
    }
  }
}
.lia-accordion:focus-visible {
  .lia-accordion-icon-wrap {
    background-color: var(--lia-bs-gray-300);
  }
}

.lia-accordion-content {
  margin: 25px 25px 0;
  padding-bottom: 25px;
  display: block;

  &:not(:last-child) {
    border-bottom: 1px solid var(--lia-bs-border-color);
  }

  @media (--lia-breakpoint-down-md) {
    padding: 0 10px 15px 15px;
    margin-left: 0;
    margin-right: 0;
  }
}
.lia-version {
  &-title {
    font-weight: var(--lia-bs-headings-font-weight);
    text-align: left;
    display: block;
  }
  &-metadata {
    color: var(--lia-bs-gray-700);
  }
  &-divider::before {
    background-color: var(--lia-bs-gray-900) !important;
  }
}
.lia-compare-time-text {
  padding-right: 3px;
}

.lia-revision-note {
  background: var(--lia-bs-gray-200);
  border-radius: var(--lia-bs-border-radius);
  color: var(--lia-bs-gray-900);
  padding: 10px;
  margin: 10px 0 0 50px;
}

.lia-cancel-schedule {
  text-decoration: line-through;
}

.lia-accordion:focus {
  outline-offset: -2px !important;
}
