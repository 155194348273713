@import '@aurora/shared-client/styles/_variables.pcss';

.lia-modal {
  .lia-header {
    border-bottom: 0;
  }
  .lia-container {
    &:before {
      content: '';
      display: block;
      position: sticky;
      top: -1px;
      height: 1px;
      box-shadow: 0px 2px 4px
        hsla(var(--lia-bs-gray-100-h), var(--lia-bs-gray-100-s), var(--lia-bs-gray-100-l), 0.6);
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      inset: 0 0 20px;
      height: 20px;
      background-color: var(--lia-bs-modal-content-bg);
    }
  }
}

.lia-compare {
  &-container-header {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid var(--lia-bs-border-color);
    border-bottom: 1px solid var(--lia-bs-border-color);
    margin: 0 30px;
    z-index: 5000;

    @media (--lia-breakpoint-down-md) {
      flex-direction: column;
      margin: 0 20px;
    }
  }
  &-header-left {
    display: flex;
    font-weight: var(--lia-font-weight-md);
    font-size: var(--lia-bs-font-size-sm);
    align-items: center;
  }
  &-version-details {
    font-size: var(--lia-bs-font-size-sm);
    color: var(--lia-bs-gray-700);
    font-weight: var(--lia-bs-font-weight-normal);
    padding-top: 15px;
    padding-bottom: 30px;

    @media (--lia-breakpoint-down-sm) {
      display: flex;
      margin-bottom: 10px;
      flex-direction: column;
    }

    &-text {
      border-right: 1px solid var(--lia-bs-border-color);
      padding-right: 15px;
      margin-right: 15px;

      @media (--lia-breakpoint-down-sm) {
        margin-bottom: 10px;
      }
    }
    &-content {
      font-weight: var(--lia-bs-headings-font-weight);
      color: var(--lia-bs-gray-700);
      position: relative;
      z-index: 999;
    }
  }

  &-tags {
    padding: 2px 5px;
    border-radius: var(--lia-bs-border-radius-sm);
    font-size: var(--lia-bs-font-size-sm);
    color: var(--lia-bs-gray-900);

    &-wrapper {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 10px;
      align-items: center;

      @media (--lia-breakpoint-down-md) {
        justify-content: flex-end;
        margin-top: 15px;
      }
    }
    &-added {
      background-color: var(--lia-rte-diff-added-color);
    }
    &-removed {
      background-color: var(--lia-rte-diff-removed-color);
      text-decoration: line-through;
    }
    &-changed {
      background-color: var(--lia-rte-diff-changed-color);
    }
  }
  &-time-text {
    padding-right: 3px;
  }
}

.lia-dropdown {
  &-toggle {
    font-weight: var(--lia-bs-font-weight-normal);
    margin-left: 10px;
    margin-right: 10px;
  }
  &-menu {
    overflow-y: scroll;
    max-height: 150px;
  }
}
